import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { store } from "../../../store";
import { getMaterialsForOrder } from "../../../redux/actions/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CustomTextField } from "../../ui/CustomTextField";
import { getCenterData } from "../../../redux/actions/center";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export const ScreenCreateOrderForm = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user);
  const isAdmin = user.role === "admin";
  const initialOrderData = location.state?.orderData || {};
  const materialsInitial = location.state?.materials || [];
  const centerId =
    user.organization === "Center" ? user.centerId : location.state?.centerId;
  const centerNumber = location.state?.centerNumber;
  const studyId = location.state?.studyId;
  const studyCode = location.state?.studyCode;
  const center = useSelector((state) => state.center.center);
  const materials = useSelector((state) => state.material.materials);
  const initialQuantities = materialsInitial.reduce((acc, material) => {
    acc[material.id] = material.quantity;
    return acc;
  }, {});
  const [quantities, setQuantities] = React.useState(initialQuantities || {});
  const [hospital, setHospital] = React.useState("");
  const [ward, setWard] = React.useState("");
  const [building, setBuilding] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [floor, setFloor] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [comment, setComment] = React.useState("");
  const [isFirstSupply, setIsFirstSupply] = React.useState(
    initialOrderData.isFirstSupply || false
  );
  const [isDemokit, setIsDemokit] = React.useState(
    initialOrderData.isDemokit || false
  );
  const [orderDate, setOrderDate] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(""); // error if no material ordered
  const [fieldErrors, setFieldErrors] = React.useState({}); // error if fields are empty
  const scrollRef = React.useRef(null);

  React.useEffect(() => {
    setHospital(initialOrderData.hospital || center?.hospital || "");
    setWard(initialOrderData.ward || center?.ward || "");
    setBuilding(initialOrderData.building || center?.building || "");
    setStreet(initialOrderData.street || center?.street || "");
    setFloor(initialOrderData.floor || center?.floor || "");
    setZipcode(initialOrderData.zipcode || center?.zipcode || "");
    setPhone(initialOrderData.phone || center?.contactPhone || "");
    setName(initialOrderData.name || (center?.contactName ? center.contactName.replace(/,\s*/, ' ') : ""));
    setEmail(initialOrderData.email || center?.contactEmail || "");
    // eslint-disable-next-line
  }, [center]);

  React.useEffect(() => {
    const currentScrollRef = scrollRef.current;

    return () => {
      // Store scroll position before component update
      if (currentScrollRef) {
        currentScrollRef.scrollTop = window.scrollY;
      }
    };
  }, [quantities]); // Run effect when `quantities` changes

  React.useEffect(() => {
    const currentScrollRef = scrollRef.current;

    // Restore scroll position after component update
    if (currentScrollRef) {
      window.scrollTo(0, currentScrollRef.scrollTop);
    }
  }, [quantities]);

  const validateFields = () => {
    const errors = {};
    const fields = { hospital, street, zipcode, phone, email, name };

    Object.keys(fields).forEach((field) => {
      if (fields[field].trim() === "") {
        errors[field] = "This field cannot be empty";
      }
    });

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const materialsWithId = materials.map((material, index) => ({
    id: material.id,
    ...material,
  }));
  const materialsTypeMaterial = materialsWithId.filter(
    (material) => material.type === "material"
  );
  const materialsTypeSupply = materialsWithId.filter(
    (material) => material.type === "supply"
  );
  const materialsTypeReagent = materialsWithId.filter(
    (material) => material.type === "reagent"
  );
  const materialsTypeDocuments = materialsWithId.filter(
    (material) => material.type === "document"
  );

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "name",
        headerName: headerName,
        flex: 0.8,
        sortable: false,
      },
      {
        field: "quantity",
        headerName: "QUANTITY",
        flex: 0.2,
        editable: true,
        sortable: false,
        renderCell: (params) => (
          <TextField
            type="number"
            value={quantities[params.id] || 0}
            onChange={(e) => {
              let value = parseInt(e.target.value, 10);
              if (isNaN(value) || value < 0) {
                value = 0;
              }
              params.api.setEditCellValue(params.id, params.field, value);
              setQuantities((prev) => ({ ...prev, [params.id]: value })); // update quantities
            }}
          />
        ),
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        disableColumnMenu
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        pageSizeOptions={[30, 50]}
        rowHeight={40}
        disableSelectionOnClick
        hideFooter
        autoHeight
      />
    );
  };

  const handleNewOrder = () => {
    const { centerNumber, city, country } = center;
    const materialsWithQuantity = materials
      .filter((material) => quantities[material.id] > 0)
      .map((material) => ({
        ...material,
        quantity: quantities[material.id],
      }));

    if (!validateFields()) {
      return;
    }

    if (materialsWithQuantity.length === 0) {
      setErrorMessage(
        "You need to add a quantity to at least one item to order"
      );
      return;
    }

    const orderData = {
      centerId,
      centerNumber,
      //shipping address
      hospital,
      ward,
      building,
      street,
      floor,
      zipcode,
      city,
      country,
      phone,
      comment,
      name,
      email,
      isFirstSupply,
      isDemokit,
      orderDate: orderDate ? orderDate.format("DD/MM/YYYY") : null,
    };

    navigate("/orders/confirm", {
      state: {
        orderData,
        materials: materialsWithQuantity,
        centerId,
        studyId,
        centerNumber,
        studyCode,
      },
    });
  };

  React.useEffect(() => {
    if (centerId) {
      store.dispatch(getMaterialsForOrder(centerId));
      store.dispatch(getCenterData(centerId));
    }
  }, [centerId]);

  return (
    <Grid item xs={12} ref={scrollRef}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <TextField
              value="SHIPPING ADDRESS"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
              sx={{ mt: 1, ml: 2 }}
            />
            <CustomTextField
              name="centerNumber"
              label="N° Site"
              value={centerNumber}
              readOnly
            />
            <CustomTextField
              name="hospital"
              value={hospital}
              onChange={(e) => setHospital(e.target.value)}
              error={!!fieldErrors.hospital}
              helperText={fieldErrors.hospital}
            />
            <CustomTextField
              name="ward"
              value={ward}
              required={false}
              onChange={(e) => setWard(e.target.value)}
            />
            <CustomTextField
              name="building"
              value={building}
              required={false}
              onChange={(e) => setBuilding(e.target.value)}
            />
            <CustomTextField
              name="street"
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              error={!!fieldErrors.street}
              helperText={fieldErrors.street}
            />
            <CustomTextField
              name="floor"
              value={floor}
              required={false}
              onChange={(e) => setFloor(e.target.value)}
            />
            <CustomTextField
              name="zipcode"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
              error={!!fieldErrors.zipcode}
              helperText={fieldErrors.zipcode}
            />
            <CustomTextField
              name="city"
              value={center?.city}
              readOnly
              InputLabelProps={{ shrink: true }}
            />
            <CustomTextField
              name="country"
              value={center?.country}
              readOnly
              InputLabelProps={{ shrink: true }}
            />
            <CustomTextField
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              error={!!fieldErrors.phone}
              helperText={fieldErrors.phone}
            />
            <CustomTextField
              name="Recipient Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              error={!!fieldErrors.name}
              helperText={fieldErrors.name}
            />
            <CustomTextField
              name="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!fieldErrors.email}
              helperText={fieldErrors.email}
            />
          </Grid>
        </Box>
        <Box sx={{ p: 2 }}>
          <div style={{ width: "100%" }}>
            <TextField
              value="MATERIAL ORDER"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <CustomDataGrid
              rows={materialsTypeMaterial}
              headerName="MATERIALS"
            />
            <CustomDataGrid rows={materialsTypeSupply} headerName="SUPPLIES" />
            <CustomDataGrid rows={materialsTypeReagent} headerName="REAGENTS" />
            <CustomDataGrid
              rows={materialsTypeDocuments}
              headerName="DOCUMENTS"
            />
          </div>
        </Box>
        <Box sx={{ p: 2 }}>
          <TextField
            id="comment"
            name="comment"
            label="Comment"
            fullWidth
            multiline
            rows={3}
            sx={{ mb: 2 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          {isAdmin && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isFirstSupply}
                      onChange={(e) => setIsFirstSupply(e.target.checked)}
                      name="isFirstSupply"
                    />
                  }
                  label="First supply"
                />
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDemokit}
                      onChange={(e) => setIsDemokit(e.target.checked)}
                      name="isDemoKit"
                    />
                  }
                  label="Demo kit"
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DatePicker
                    label="Order date"
                    name="orderDate"
                    value={orderDate}
                    onChange={(newValue) => {
                      setOrderDate(newValue);
                    }}
                    format="DD/MM/YYYY"
                    maxDate={dayjs()}
                    sx={{ width: "100%" }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={handleNewOrder}
              style={{ padding: "16px 88px" }}
            >
              Create Order
            </Button>
          </Grid>
          {errorMessage && (
            <div
              style={{
                color: "#721c24",
                backgroundColor: "#f8d7da",
                borderColor: "#f5c6cb",
                padding: "10px",
                margin: "15px",
                border: "1px solid transparent",
                borderRadius: "4px",
              }}
            >
              {errorMessage}
            </div>
          )}
        </Box>
      </Paper>
    </Grid>
  );
};
