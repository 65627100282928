import { USER } from "../constants";

const initialState = {
  user: null,
  users: [],
  isLoadingUsers: false,
  createdUser: false,
  updatedUser: false,
  isLoadingUser: true,
  error: null,
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case USER.USER_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingUsers: true,
        createdUser: false,
      };
    case USER.USER_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        createdUser: false,
        error: action.payload,
      };
    case USER.USER_GET_ALL_SUCCESS:
      if (action.payload.employees && action.payload.monitors) {
        return {
          ...state,
          employees: action.payload.employees,
          monitors: action.payload.monitors,
          isLoadingUsers: false,
          error: null,
        };
      } else {
        return {
          ...state,
          users: action.payload,
          isLoadingUsers: false,
          error: null,
        };
      }
    case USER.USER_CREATE:
      return {
        ...state,
        isLoadingUsers: true,
        createdUser: false,
      };
    case USER.USER_CREATE_FAILURE:
      return {
        ...state,
        createUserError: action.payload,
      };
    case USER.USER_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        createdUser: true,
      };
    case USER.USER_UPDATE:
      return {
        ...state,
        isLoadingUsers: true,
        updatedUser: false,
      };
    case USER.USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
        updatedUser: true,
      };
    case USER.USER_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        error: action.payload,
      };
    case USER.SET_USER:
      return {
        ...state,
        isLoadingUser: false,
        user: action.payload,
      };
    case USER.USER_CHANGE_PASSWORD:
      return {
        ...state,
        isLoadingUsers: true,
      };
    case USER.USER_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingUsers: false,
      };
    case USER.USER_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoadingUsers: false,
        error: action.payload,
      };
    case USER.CLEAN_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default userReducer;
