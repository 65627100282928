import React, { useEffect, useState } from "react";
import { getHttpRequests } from "../../../services/auditService";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { store } from "../../../store";
import { useSelector } from "react-redux";
import { getUsers } from "../../../redux/actions/user";
import { FormControl, InputLabel } from "@mui/material";

export const ScreenAudit = () => {
  const users = useSelector((state) => state.user.users);
  const [httpRequests, setHttpRequests] = useState([]);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [userId, setUserId] = useState("Unauthenticated user");
  const [filter, setFilter] = useState(false);

  const handleFilter = () => {
    setFilter(!filter);
  };

  useEffect(() => {
    const fetchData = async () => {
      const formattedStartDate = startDate
        ? new Date(startDate).toISOString().split("T")[0]
        : "";
      const formattedEndDate = endDate
        ? new Date(endDate).toISOString().split("T")[0]
        : "";

      const data = await getHttpRequests({
        page,
        pageSize,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        userId,
      });
      setHttpRequests(Array.isArray(data) ? data : []);
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line
  }, [page, pageSize, filter]);

  // Fetch users
  React.useEffect(() => {
    store.dispatch(getUsers());
  }, []);

  const columns = [
    {
      field: "user",
      headerName: "User",
      width: 150,
      editable: true,
      valueGetter: (params) => {
        if (params.value === "Unauthenticated user") {
          return "Unauthenticated User";
        }

        const userId = parseInt(params.value, 10);
        const user = users.find((user) => user.id === userId);

        if (user) {
          return `${user.name.toUpperCase()} ${user.firstname}`;
        }
        return "Unknown User";
      },
    },
    {
      field: "ip",
      headerName: "IP Address",
      width: 200,
      editable: true,
    },
    {
      field: "method",
      headerName: "Method",
      width: 100,
      editable: true,
    },
    {
      field: "url",
      headerName: "URL",
      width: 200,
      editable: true,
    },
    {
      field: "datetime",
      headerName: "Date Time",
      width: 180,
      editable: true,
      type: "dateTime",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
    },
  ];
  return (
    <Grid item xs={12}>
      <Paper style={{ padding: "16px" }}>
        <div style={{ display: "flex", gap: "8px" }}>
          <FormControl variant="outlined">
            <InputLabel id="user-select-label">User</InputLabel>
            <Select
              label="User"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              variant="outlined"
            >
              <MenuItem key="Unauthenticated user" value="Unauthenticated user">
                Unauthenticated User
              </MenuItem>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {`${user.name}, ${user.firstname}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <TextField
            label="End Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
          <Button
            variant="contained"
            style={{ padding: "16px" }}
            onClick={() => handleFilter()}
          >
            Filter
          </Button>
        </div>
        <div style={{ width: "100%", height: "85vh" }}>
          <DataGrid
            rows={httpRequests}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            rowHeight={40}
            pagination
          />
        </div>
        <Button
          variant="contained"
          style={{ padding: "16px", marginTop: "16px", float: "right" }}
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next page
        </Button>
      </Paper>
    </Grid>
  );
};
