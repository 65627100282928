import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const DestructionDateDialog = ({
  open,
  onClose,
  destructionDate,
  handleDateChange,
  handleConfirmDestruction,
}) => {
  return (
    <Dialog
      PaperProps={{
        style: { padding: '20px' },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Select a destruction date</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            value={destructionDate || dayjs()}
            onChange={handleDateChange}
            maxDate={dayjs()}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" style={{ padding: '16px', margin: '16px' }} onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: '16px', margin: '16px' }}
          onClick={handleConfirmDestruction}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DestructionDateDialog;