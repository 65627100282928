import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  Button,
} from '@mui/material';

const TransferProductionDialog = ({
  open,
  onClose,
  selectedProduction,
  selectedCenterId,
  setSelectedCenterId,
  centers,
  handleTransferProduction,
}) => {
  return (
    <Dialog
      PaperProps={{
        style: { padding: '20px' },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Transfer a production to a center</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedProduction ? selectedProduction.Material.name : ''}{' '}
        </DialogContentText>
        <DialogContentText>
          Reference:{' '}
          {selectedProduction ? selectedProduction.reference : ''}
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <DialogContentText>Select a center</DialogContentText>
        <Select
          value={selectedCenterId || 'At CDL'}
          onChange={(e) => setSelectedCenterId(e.target.value)}
        >
          {selectedProduction?.centerId === null && (
            <MenuItem value="At CDL">At CDL</MenuItem>
          )}
          {centers.map((center) => (
            <MenuItem key={center.id} value={center.id}>
              {center.centerNumber}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: '16px', margin: '16px' }}
          onClick={handleTransferProduction}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferProductionDialog;