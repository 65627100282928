import React from 'react';
import { Grid, TextField, Typography, InputAdornment } from '@mui/material';

const PackageDetails = ({ index, packageData }) => {
  return (
    <React.Fragment key={index}>
      <Grid item xs={3}>
        <Typography variant="body1">Package {index + 1}:</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Length"
          type="number"
          value={packageData?.length || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Height"
          type="number"
          value={packageData?.height || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Width"
          type="number"
          value={packageData?.width || ""}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Volumetric Weight"
          value={packageData?.volumetricWeight || ""}
          type="number"
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};

export default PackageDetails;