const getUniqueOptions = (rows, field) => {
  const uniqueValues = new Set();
  rows.forEach((row) => {
    const value = field.split('.').reduce((acc, part) => acc && acc[part], row);
    if (value) {
      uniqueValues.add(value);
    }
  });
  return Array.from(uniqueValues)
    .sort((a, b) => a.localeCompare(b)) // Sort alphabetically
    .map((value) => ({
      value,
      label: value,
    }));
};

export default getUniqueOptions;