import * as React from "react";
import { useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { SearchInput } from "../Search";
// import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { drawerWidth } from "./Drawer";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarComponent = ({ open, toggleDrawer, showTitle, user }) => {
  const navigate = useNavigate();

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {showTitle}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {/* <SearchInput></SearchInput> */}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" spacing={2}>
          <IconButton
            aria-label="show 2 new notifications"
            color="inherit"
            disabled
          >
            {/* <Badge badgeContent={3} color="error"> */}
            <NotificationsIcon />
            {/* </Badge> */}
          </IconButton>
          {user && (
            <Avatar
              sx={{ bgcolor: "#DAA520" }}
              onClick={() => navigate("/profile")}
            >
              {user.firstname.charAt(0).toUpperCase() +
                user.name.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
