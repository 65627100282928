import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const EditShippingAddressDialog = ({
  open,
  handleClose,
  hospital,
  setHospital,
  ward,
  setWard,
  building,
  setBuilding,
  street,
  setStreet,
  floor,
  setFloor,
  zipcode,
  setZipcode,
  shippingAddress,
  setShippingAddress,
  errorMessage,
  handleSave
}) => {
  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={handleClose}
      aria-hidden={!open}
      {...(!open && { inert: "true" })}
    >
      <DialogTitle>Edit the shipping address</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Hospital"
          type="text"
          fullWidth
          value={hospital}
          onChange={(e) => setHospital(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Ward"
          type="text"
          fullWidth
          value={ward}
          onChange={(e) => setWard(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Building"
          type="text"
          fullWidth
          value={building}
          onChange={(e) => setBuilding(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Street"
          type="text"
          fullWidth
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Floor"
          type="text"
          fullWidth
          value={floor}
          onChange={(e) => setFloor(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Zipcode"
          type="text"
          fullWidth
          value={zipcode}
          onChange={(e) => setZipcode(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Shipping Address"
          type="text"
          fullWidth
          multiline
          maxRows={4}
          value={shippingAddress}
          onChange={(e) => setShippingAddress(e.target.value)}
        />
        {errorMessage && (
          <div style={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          sx={{ m: 1, padding: "8px 16px" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditShippingAddressDialog;