import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import * as React from "react";
import { useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import {
  getOrders,
  getOrdersbyCenter,
  getOrdersbyStudy,
  updateOrder,
} from "../../../redux/actions/order";
import { store } from "../../../store";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import CSVDownloader from "../../ui/CSVDownloader";
import { isWeekend } from "date-fns";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { showSuccessAlert } from "../../ui/utils/AlertUtils";
import CustomFilter from "../../table/CustomFilter";
import getUniqueOptions from "../../table/getUniqueOptions";

const SPACING = 50;

export const ScreenOrders = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const orders = useSelector((state) => state.order.orders);
  const isLoading = useSelector((state) => state.order.isLoadingOrders);
  const studyId = location.state?.studyId;
  const centerId = location.state?.centerId;
  const centerNumber = location.state?.centerNumber;
  const studyCode = location.state?.studyCode;
  const cdlUser = useSelector(
    (state) => state.user.user.organization === "CDL"
  );
  const centerUser = useSelector(
    (state) => state.user.user.organization === "Center"
  );
  const [selectedRows, setSelectedRows] = React.useState([]);
  const today = new Date();
  const formattedDate = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  let filename = `orders_${formattedDate}`;
  if (studyCode || centerNumber) {
    filename = `${studyCode ? studyCode + "_" : ""}${
      centerNumber ? centerNumber + "_" : ""
    }orders_${formattedDate}`;
  }
  const [FSDialogOpen, setFSDialogOpen] = React.useState(false);
  const [isFirstSupply, setIsFirstSupply] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState({});
  const updatedOrder = useSelector((state) => state.order.updatedOrder);
  const [statusOptions, setStatusOptions] = React.useState([]);
  const [carrierOptions, setCarrierOptions] = React.useState([]);
  const [studyCodeOptions, setStudyCodeOptions] = React.useState([]);
  const [centerNumberOptions, setCenterNumberOptions] = React.useState([]);
  const [ordersWithFormattedDates, setOrdersWithFormattedDates] =
    React.useState([]);

  const handleFSConfirm = () => {
    store.dispatch(updateOrder(selectedOrder.id, { isFirstSupply }));
    setFSDialogOpen(false);
    showSuccessAlert("Order updated successfully");
  };

  function calculateProcessingTime(order) {
    if (!order.sendingDate || order.sendingDate === "Not sent yet") {
      return "";
    }
    if (order.isFirstSupply) {
      return "First Supply";
    }
    const orderDate = moment(order.orderDate, "DD/MM/YYYY")
      .startOf("day")
      .toDate();
    const sendingDate = moment(order.sendingDate, "DD/MM/YYYY")
      .startOf("day")
      .toDate();

    // Check if the sendingDate is before the orderDate
    if (sendingDate < orderDate) {
      return "Not Possible";
    }

    let workingDays = 0;

    for (
      let day = new Date(orderDate);
      day <= sendingDate;
      day.setDate(day.getDate() + 1)
    ) {
      if (!isWeekend(day)) {
        workingDays++;
      }
    }

    return workingDays > 0 ? workingDays - 1 : 0;
  }

  const columns = [
    {
      field: "orderNumber",
      headerName: "#Order",
      minWidth: 150,
      renderCell: (params) => (
        <div
          style={{
            cursor: "pointer",
            textDecoration: "none",
            color: "inherit",
          }}
          onClick={() => {
            navigate("/order", {
              state: {
                orderId: params.row.id,
                studyId: params.row.studyId,
                centerNumber: params.row.centerNumber,
                centerId: params.row.centerId,
                studyCode: cdlUser
                  ? params.row.studyCode
                  : params.row.sponsorCode,
              },
            });
          }}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 150,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: "Status",
          value: "status",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter label="Status" {...props} options={statusOptions} />
          ),
        },
      ],
    },
    {
      field: "orderDate",
      type: "date",
      headerName: "Order Date",
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Order date
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value ? moment(params.value).format("DD/MM/YYYY") : "";
      },
    },
    {
      field: "sendingDate",
      type: "date",
      headerName: "Sending Date",
      minWidth: 150,
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Sending date
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>{" "}
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : "Not sent yet";
      },
    },
    {
      field: "deliveryDate",
      type: "date",
      minWidth: 150,
      headerName: "Delivery Date",
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Delivery Date
          <br />
          <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>{" "}
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : "Not delivered yet";
      },
    },
    {
      field: "centerValidatedDate",
      minWidth: 150,
      type: "date",
      headerName: "Center Validated Date",
      renderHeader: () => (
        <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
          Center validation
          <br />
          date <span style={{ fontSize: "x-small" }}>(DD/MM/YYYY)</span>
        </div>
      ),
      valueGetter: (params) => {
        return params.value;
      },
      renderCell: (params) => {
        return params.value
          ? moment(params.value).format("DD/MM/YYYY")
          : "Not validated yet";
      },
    },
  ];
  if (!centerUser) {
    columns.push(
      {
        field: "processingTime",
        headerName: "Processing Time",
        minWidth: 130,
        renderHeader: () => (
          <div style={{ whiteSpace: "normal", lineHeight: "normal" }}>
            Processing
            <br />
            time
          </div>
        ),
        valueGetter: (params) => calculateProcessingTime(params.row),
      },
      {
        field: "carrier",
        headerName: "Carrier",
        minWidth: 130,
        filterable: true,
        renderCell: (params) => params.value,
        filterOperators: [
          {
            label: "Carrier",
            value: "carrier",
            getApplyFilterFn: (filterItem) => {
              if (!filterItem.value) {
                return null;
              }
              return ({ value }) => value === filterItem.value;
            },
            InputComponent: (props) => (
              <CustomFilter
                label="Carrier"
                options={carrierOptions}
                {...props}
              />
            ),
          },
        ],
      },
      {
        field: "packingSlip",
        headerName: "Airwaybill",
        headerAlign: "left",
        minWidth: 130,
      },
      { field: "comment", headerName: "Comment", minWidth: 300 }
    );
  }

  if (!centerId) {
    columns.unshift({
      field: "centerNumber",
      headerName: "Center",
      flex: 1,
      minWidth: 100,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: "Center",
          value: "centerNumber",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={centerNumberOptions} />
          ),
        },
      ],
    });
  }

  if (!studyId && cdlUser) {
    columns.unshift({
      field: "studyCode",
      headerName: "Study code",
      flex: 1,
      minWidth: 110,
      filterable: true,
      renderCell: (params) => params.value,
      filterOperators: [
        {
          label: "Study code",
          value: "studyCode",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return ({ value }) => value === filterItem.value;
          },
          InputComponent: (props) => (
            <CustomFilter {...props} options={studyCodeOptions} />
          ),
        },
      ],
    });
  }

  React.useEffect(() => {
    if (centerId) {
      store.dispatch(getOrdersbyCenter(centerId));
    } else if (studyId) {
      store.dispatch(getOrdersbyStudy(studyId));
    } else {
      store.dispatch(getOrders());
    }
  }, [studyId, centerId, updatedOrder]);

  // Map over the orders and format the dates
  React.useEffect(() => {
    if (orders.length > 0) {
      setOrdersWithFormattedDates(
        // todo : remove the filter when study is validated
        orders.filter(order => order.Center.Study.cdlStudyCode !== '2021-07').map((order) => ({
          studyCode: order.Center?.Study?.cdlStudyCode,
          sponsorCode: order.Center?.Study?.sponsorStudyCode,
          id: order.id,
          studyId: order.Center?.Study?.id,
          centerId: order.centerId,
          orderNumber: order.orderNumber,
          status: order.status,
          isFirstSupply: order.isFirstSupply,
          orderDate: new Date(order.orderDate),
          centerNumber: order.Center.centerNumber,
          sendingDate:
            order.shipments.length > 0
              ? new Date(order.shipments[0].sendingDate)
              : null,
          deliveryDate:
            order.shipments && order.shipments.length > 0
              ? order.shipments[0].deliveryDate
                ? new Date(order.shipments[0].deliveryDate)
                : null
              : null,
          centerValidatedDate:
            order.shipments && order.shipments.length > 0
              ? order.shipments[0].confirmedDeliveryDate
                ? new Date(order.shipments[0].confirmedDeliveryDate)
                : null
              : null,
          carrier:
            order.shipments.length > 0 ? order.shipments[0].carrier : null,
          packingSlip:
            order.shipments.length > 0 ? order.shipments[0].packingSlip : null,
          comment: order.comment,
        }))
      );
    }
  }, [orders]);

  React.useEffect(() => {
    if (ordersWithFormattedDates.length > 0) {
      setStatusOptions(getUniqueOptions(ordersWithFormattedDates, "status"));
      setCarrierOptions(getUniqueOptions(ordersWithFormattedDates, "carrier"));
      setStudyCodeOptions(
        getUniqueOptions(ordersWithFormattedDates, "studyCode")
      );
      setCenterNumberOptions(
        getUniqueOptions(ordersWithFormattedDates, "centerNumber")
      );
    }
  }, [ordersWithFormattedDates]);

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "100%", height: "90vh" }}>
          <DataGrid
            slots={{
              noRowsOverlay: NoRowOverlay,
              loadingOverlay: LinearProgress,
            }}
            rows={ordersWithFormattedDates}
            columns={columns}
            loading={isLoading}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            rowHeight={40}
            pageSizeOptions={[20, 50]}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              const selectedOrders = ordersWithFormattedDates
                .filter((order) => newSelection.includes(order.id))
                .map((order) => {
                  const filteredOrder = {};
                  columns.forEach((column) => {
                    if (column.field === "processingTime") {
                      filteredOrder[column.headerName] =
                        calculateProcessingTime(order);
                    } else {
                      filteredOrder[column.headerName] = order[column.field];
                    }
                  });
                  return filteredOrder;
                });
              setSelectedRows(selectedOrders);
            }}
            onCellDoubleClick={(params) => {
              if (params.field === "processingTime" && cdlUser) {
                setIsFirstSupply(params.row.isFirstSupply);
                setFSDialogOpen(true);
                setSelectedOrder(params.row);
              }
            }}
          />
          <Dialog
            PaperProps={{
              style: { padding: "20px" },
            }}
            open={FSDialogOpen}
            onClose={() => setFSDialogOpen(false)}
          >
            <DialogTitle>Is this order First Supply ?</DialogTitle>
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isFirstSupply}
                    onChange={(event) => setIsFirstSupply(event.target.checked)}
                    aria-label="FirstSupply"
                  />
                }
                label="First Supply"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setFSDialogOpen(false)}>Close</Button>
              <Button onClick={handleFSConfirm} variant="contained">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {selectedRows.length > 0 && (
          <CSVDownloader
            data={selectedRows}
            filename={filename}
            name="Selected Orders"
          />
        )}
      </Paper>
      {centerId && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            navigate("/orders/new", {
              state: { centerId, studyId, centerNumber, studyCode },
            });
          }}
          style={{ position: "absolute", right: SPACING, bottom: SPACING }}
        >
          <AddIcon />
        </Fab>
      )}
    </Grid>
  );
};
