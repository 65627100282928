import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Box } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const AttributeToPatientDialog = ({
  open,
  onClose,
  selectedStatusRow,
  patientNumber,
  setPatientNumber,
  attributionDate,
  setAttributionDate,
  showAlert,
  showDateAlert,
  handleStatusFormSubmit,
  setShowDateAlert
}) => {
  return (
    <Dialog
      PaperProps={{
        style: { padding: "20px" },
      }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Attribute to a patient </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {selectedStatusRow ? selectedStatusRow.Material.name : ""}{" "}
        </DialogContentText>
        <DialogContentText>
          Reference: {selectedStatusRow ? selectedStatusRow.reference : ""}
        </DialogContentText>
        <Box display="flex" justifyContent="space-between" sx={{ mt: 2 }}>
          <TextField
            label="Patient Number"
            onChange={(e) => setPatientNumber(e.target.value)}
            sx={{ marginRight: 1 }}
          />
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="en-gb"
          >
            <DatePicker
              label="Attribution Date"
              name="attributionDate"
              value={dayjs(attributionDate)}
              onChange={(newValue) => {
                if (
                  dayjs(newValue).isAfter(
                    dayjs(selectedStatusRow.expirationDate)
                  )
                ) {
                  setShowDateAlert(true);
                } else {
                  setShowDateAlert(false);
                  setAttributionDate(newValue.format("YYYY-MM-DD"));
                }
              }}
              format="DD/MM/YYYY"
              maxDate={dayjs()} // Disable dates after today
              sx={{ width: "50%" }}
            />
          </LocalizationProvider>
        </Box>
        {showAlert && (
          <div style={{ color: "red", marginTop: "10px" }}>
            Please enter a patient number
          </div>
        )}
        {showDateAlert && (
          <div style={{ color: "red", marginTop: "10px" }}>
            Selected date is after the expiration date
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          style={{ padding: "16px", margin: "16px" }}
          onClick={() =>
            handleStatusFormSubmit(patientNumber, attributionDate)
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributeToPatientDialog;