import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import { store } from "../../../store";
import { getOrder } from "../../../redux/actions/order";
import { NoRowOverlay } from "../../table/NoRowOverlay";
import UserInfo from "../../ui/UserInfo";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import dayjs from "dayjs";
import { getShipmentById } from "../../../services/shipmentService";
import { getAORsByShipmentId } from "../../../services/aorService";

export const ScreenAor = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const orderId = location.state.orderId;
  const studyId = location.state.studyId;
  const centerId = location.state.centerId;
  const centerNumber = location.state.centerNumber;
  const studyCode = location.state.studyCode;
  const order = useSelector((state) => state.order.order);
  const [isLoading, setIsLoading] = React.useState(true);
  const [shipment, setShipment] = React.useState(null);
  const [AORs, setAORs] = React.useState([]);

  const shipmentIndex = location.state.shipmentIndex;

  const [materialsTypeMaterial, setMaterialsTypeMaterial] = React.useState(
    order.Supplies.filter((supply) => supply.Material.type === "material")
  );
  const [materialsTypeSupply, setMaterialsTypeSupply] = React.useState(
    order.Supplies.filter((supply) => supply.Material.type === "supply")
  );
  const [materialsTypeReagent, setMaterialsTypeReagent] = React.useState(
    order.Supplies.filter((supply) => supply.Material.type === "reagent")
  );
  const [materialsTypeDocuments, setMaterialsTypeDocuments] = React.useState(
    order.Supplies.filter((supply) => supply.Material.type === "documents")
  );

  React.useEffect(() => {
    getShipmentById(order.shipments[shipmentIndex].id).then((shipment) => {
      setShipment(shipment);
    });
  }, [order.shipments, shipmentIndex]);

  React.useEffect(() => {
    if (shipment) {
      getAORsByShipmentId(shipment.id).then((aors) => {
        if (aors) {
          setAORs(aors);
        }
      });
    }
  }, [shipment]);

  const CustomDataGrid = ({ rows, headerName }) => {
    const columns = [
      {
        field: "materialName",
        headerName: headerName,
        flex: 0.4,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: (params) => params.row.Material.name,
      },
      {
        field: "shippingQuantity",
        headerName: "Sent Quantity",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const productionsWithSameMaterial =
            shipment?.productions?.filter(
              (production) => production.materialId === params.row.Material.id
            ) || [];
          return <div>{productionsWithSameMaterial.length}</div>;
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
                padding: "5px",
              }}
            >
              Quantity
              <br />
              Sent
            </div>
          );
        },
      },
      {
        field: "quantityReceived",
        headerName: "Quantity Received",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const matchingAor = AORs.find(
            (aor) => aor.materialId === params.row.Material.id
          );
          return <div>{matchingAor?.quantityReceived}</div>;
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
                padding: "5px",
              }}
            >
              Quantity
              <br />
              Received
            </div>
          );
        },
      },
      {
        field: "materialConform",
        headerName: "Material Conform ?",
        flex: 0.1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const matchingAor = AORs.find(
            (aor) => aor.materialId === params.row.Material.id
          );
          return <Checkbox checked={matchingAor?.materialConform} disabled />;
        },
        renderHeader: (params) => {
          return (
            <div
              style={{
                lineHeight: "1.5",
                whiteSpace: "normal",
              }}
            >
              Material
              <br />
              Conform ?
            </div>
          );
        },
      },
      // Add a comment section for non-conform material
      {
        field: "comment",
        headerName: "If not, describe the error.",
        flex: 0.2,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const matchingAor = AORs.find(
            (aor) => aor.materialId === params.row.Material.id
          );
          return <div>{matchingAor?.comment}</div>;
        },
      },
    ];

    if (rows.length === 0) {
      return <div></div>;
    }

    return (
      <DataGrid
        slots={{
          noRowsOverlay: NoRowOverlay,
          loadingOverlay: LinearProgress,
        }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 30 },
          },
        }}
        rowHeight={40}
        pageSizeOptions={[30, 50]}
        disableSelectionOnClick
        hideFooter
        autoHeight
        hideScrollbar
      />
    );
  };

  // fetch order
  React.useEffect(() => {
    store.dispatch(getOrder(orderId)).then(() => setIsLoading(false));
  }, [orderId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid item xs={12}>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ p: 2 }}>
          <div style={{ width: "100%" }}>
            <TextField
              value="ACKNOWLEDGEMENT OF RECEIPT"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center", fontWeight: "bold" },
              }}
              sx={{ marginBottom: 2 }}
              fullWidth
            />
            <TextField
              value="MATERIAL ORDER"
              InputProps={{
                readOnly: true,
              }}
              inputProps={{
                style: { textAlign: "center" },
              }}
              fullWidth
            />
            <CustomDataGrid
              rows={materialsTypeMaterial}
              setRows={setMaterialsTypeMaterial}
              headerName="Material"
            />
            <CustomDataGrid
              rows={materialsTypeSupply}
              setRows={setMaterialsTypeSupply}
              headerName="Supply"
            />
            <CustomDataGrid
              rows={materialsTypeReagent}
              setRows={setMaterialsTypeReagent}
              headerName="Reagent"
            />
            <CustomDataGrid
              rows={materialsTypeDocuments}
              setRows={setMaterialsTypeDocuments}
              headerName="Document"
            />
          </div>
          <UserInfo
            title="PERSON IN CHARGE OF RECEPTION"
            user={shipment?.receivedBy}
            date={dayjs(shipment?.confirmedDeliveryDate).format("DD/MM/YYYY")}
          />

          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate("/order", {
                state: { orderId, order, centerNumber, studyCode, studyId, centerId },
              });
            }}
            style={{ padding: "16px 88px", margin: "16px" }}
          >
            Back to order
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
};
export default ScreenAor;
