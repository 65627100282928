import React from 'react';
import { Grid, TextField, Typography, InputAdornment } from '@mui/material';

const HandlePackageDetails = ({ index, packageData, packages, setPackages }) => {
  const handleDimensionChange = (dimension, value) => {
    const newPackages = [...packages];
    newPackages[index] = {
      ...newPackages[index],
      [dimension]: Number(value),
    };
    if (
      newPackages[index].length &&
      newPackages[index].height &&
      newPackages[index].width
    ) {
      newPackages[index].volumetricWeight =
        (newPackages[index].length *
          newPackages[index].height *
          newPackages[index].width) /
        5000;
    }
    setPackages(newPackages);
  };

  return (
    <React.Fragment key={index}>
      <Grid item xs={3}>
        <Typography variant="body1">Package {index + 1}:</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Length"
          name="length"
          type="number"
          value={packageData ? packageData.length : ""}
          onChange={(e) => handleDimensionChange("length", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          inputProps={{ min: 0 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Height"
          name="height"
          type="number"
          value={packageData ? packageData.height : ""}
          onChange={(e) => handleDimensionChange("height", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          inputProps={{ min: 0 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Width"
          name="width"
          type="number"
          value={packageData ? packageData.width : ""}
          onChange={(e) => handleDimensionChange("width", e.target.value)}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          inputProps={{ min: 0 }}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label="Volumetric Weight"
          name="volumetricWeight"
          value={(packageData && packageData.volumetricWeight) || 0}
          type="number"
          InputProps={{
            readOnly: true,
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          fullWidth
          disabled
        />
      </Grid>
    </React.Fragment>
  );
};

export default HandlePackageDetails;