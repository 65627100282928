import { CENTER } from "../constants";

const initialState = {
  center: null,
  centers: [],
  isLoadingCenters: false,
  createdCenter: false,
  updatedCenter: false,
  deletedCenter: false,
  isLoadingCenter: false,
  error: null,
};

const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CENTER.CENTER_GET_ALL:
      return {
        ...state,
        tokenAccess: null,
        isLoadingCenters: true,
        createdCenter: false,
      };
    case CENTER.CENTER_GET_ALL_FAILURE:
      return {
        ...state,
        isLoadingCenters: false,
        createdCenter: false,
        error: action.payload,
      };
    case CENTER.CENTER_GET_ALL_SUCCESS:
      return {
        ...state,
        isLoadingCenters: false,
        centers: action.payload,
        createdCenter: false,
        error: null,
      };
    case CENTER.CENTER_GET_ONE:
      return {
        ...state,
        isLoadingCenter: true,
        error: null,
      };
    case CENTER.CENTER_GET_ONE_SUCCESS:
      return {
        ...state,
        isLoadingCenter: false,
        center: action.payload,
        error: null,
      };
    case CENTER.CENTER_GET_ONE_FAILURE:
      return {
        ...state,
        isLoadingCenter: false,
        center: null,
        error: action.payload,
      };
    case CENTER.CENTER_CREATE:
      return {
        ...state,
        isLoadingCenter: true,
        error: null,
      };
    case CENTER.CENTER_CREATE_SUCCESS:
      return {
        ...state,
        isLoadingCenter: false,
        createdCenter: true,
        error: null,
      };
    case CENTER.CENTER_CREATE_FAILURE:
      return {
        ...state,
        isLoadingCenter: false,
        createdCenter: false,
        error: action.payload,
      };
    case CENTER.CENTER_UPDATE:
      return {
        ...state,
        isLoadingCenter: true,
        updatedCenter: false,
        error: null,
      };
    case CENTER.CENTER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingCenter: false,
        updatedCenter: true,
        error: null,
        center: action.payload,
      };
    case CENTER.CENTER_UPDATE_FAILURE:
      return {
        ...state,
        isLoadingCenter: false,
        updatedCenter: false,
        error: action.payload,
      };
    case CENTER.CENTER_DELETE:
      return {
        ...state,
        isLoadingCenter: true,
        deletedCenter: false,
        error: null,
      };
    case CENTER.CENTER_DELETE_SUCCESS:
      return {
        ...state,
        isLoadingCenter: false,
        deletedCenter: true,
        error: null,
      };
    case CENTER.CENTER_DELETE_FAILURE:
      return {
        ...state,
        isLoadingCenter: false,
        deletedCenter: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
