import { USER } from "../constants";
import { instance } from "../../axios";

let getUsers = () => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_GET_ALL,
    });
    instance({
      method: "GET",
      url: "/user/users",
    })
      .then((results) => {
        dispatch({
          type: USER.USER_GET_ALL_SUCCESS,
          payload: results.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let getCenterUsers = (centerId) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_GET_ALL,
    });
    instance({
      method: "GET",
      url: `/user/center/${centerId}`,
    })
      .then((results) => {
        dispatch({
          type: USER.USER_GET_ALL_SUCCESS,
          payload: {
            employees: results.data.employees,
            monitors: results.data.monitors,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_GET_ALL_FAILURE,
          payload: error,
        });
      });
  };
};

let editUser = (id, userDetails) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_UPDATE,
    });
    return instance({
      method: "PUT",
      url: `/user/${id}/contact`,
      data: userDetails,
    })
      .then((results) => {
        dispatch({
          type: USER.USER_UPDATE_SUCCESS,
          payload: results.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_UPDATE_FAILURE,
          payload: error,
        });
        return false;
      });
  };
};

let updateUser = ({ id, updates }) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_UPDATE,
    });
    return instance({
      method: "PUT",
      url: `user/update/${id}`,
      data: updates,
    })
      .then((results) => {
        dispatch({
          type: USER.USER_UPDATE_SUCCESS,
          payload: results.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_UPDATE_FAILURE,
          payload: error,
        });
        return false;
      });
  };
};

let deactivateUser = (id) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_UPDATE,
    });
    return instance({
      method: "PUT",
      url: `user/${id}/status`,
      data: { status: "inactive" },
    })
      .then((results) => {
        dispatch({
          type: USER.USER_UPDATE_SUCCESS,
          payload: results.data.user,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_UPDATE_FAILURE,
          payload: error,
        });
        return false;
      });
  };
};

let setUserAction = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER.SET_USER,
      payload: user,
    });
  };
};

let cleanUserAction = () => {
  return (dispatch) => {
    dispatch({
      type: USER.CLEAN_USER,
    });
  };
};

let changePassword = (id, newPassword) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_CHANGE_PASSWORD,
    });
    return instance({
      method: "PUT",
      url: `user/${id}`,
      data: { newPassword: newPassword },
    })
      .then((results) => {
        dispatch({
          type: USER.USER_CHANGE_PASSWORD_SUCCESS,
          payload: results.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_CHANGE_PASSWORD_FAILURE,
          payload: error.message,
        });
        return false;
      });
  };
};

let createUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: USER.USER_CREATE,
    });
    return instance({
      method: "POST",
      url: "/user",
      data: user,
    })
      .then((results) => {
        dispatch({
          type: USER.USER_CREATE_SUCCESS,
          payload: results.data,
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: USER.USER_CREATE_FAILURE,
          payload: error,
        });
        return false;
      });
  };
};

export {
  setUserAction,
  cleanUserAction,
  getUsers,
  getCenterUsers,
  createUser,
  updateUser,
  editUser,
  changePassword, deactivateUser
};
